<svelte:head>
  <title>Guidgenerator.io - Impressum</title>
  <meta name="robots" content="noindex nofollow" />
</svelte:head>

<h2>Impressum</h2>
<a href="/"><center><img src="favicon.png" alt="id icon" /></center></a>
<p>
  Matthias Lischka<br />
  Aichhof 3<br />
  2831 Warth<br />
  Austria <br />
</p>

<p><a href="mailto:matthias@guidgenerator.io">matthias@guidgenerator.io</a></p>

<style>
  header {
    padding-top: 20vh;
  }
</style>
