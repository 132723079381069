<svelte:options accessors />

<script lang="ts">
  import { addToast } from "./toast/store";

  export let guids: string[] = [];

  export function copyGuidsToClipboard() {
    var text = guids.join("\n");
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Guid copied to clipboard");
        displaySuccessInfoToast();
      },
      function (err) {
        console.error("Async: Could not copy guid to clipboard: ", err);
      }
    );
  }

  function displaySuccessInfoToast() {
    addToast({
      message: "Copied to Clipboard",
      type: "info",
      dismissable: true,
      timeout: 800,
    });
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    var previousFocusElement = document.activeElement as HTMLElement;
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        console.log("Fallback: Guid copied to clipboard");
        displaySuccessInfoToast();
      } else {
        console.log("Fallback: Could not copy guid to clipboard");
      }
    } catch (err) {
      console.error("Fallback: Could not copy guid to clipboard: ", err);
    }

    document.body.removeChild(textArea);
    previousFocusElement.focus();
  }

  let copyTipDisplayed = false;

  function clickHandler() {
    if (!copyTipDisplayed) {
      copyTipDisplayed = true;
      addToast({
        message:
          "Tip: You can use Ctrl+C inside the textbox to copy the generated GUIDs",
        type: "info",
        dismissable: true,
        timeout: 5000,
      });
    }
    copyGuidsToClipboard();
  }
</script>

<button type="button" on:click={clickHandler}>📋</button>
