<script lang="ts">
    import { validate as uuidValidate, version as uuidVersion } from "uuid";
    import { useFocus } from "svelte-navigator";

    const registerFocus = useFocus();

    let guidInput;
    let guidValue;

    let isValid: Boolean | null = null;
    let guidVersion: Number | null = null;

    function submit() {
        console.log(guidValue);
        isValid = uuidValidate(guidValue);
        guidVersion = isValid ? uuidVersion(guidValue) : null;
    }
</script>

<svelte:head>
    <title>Guidgenerator.io - Validator</title>
</svelte:head>

<form on:submit|preventDefault={submit}>
    <h2 class="m-5">Enter the GUID you want to validate:</h2>

    <input
        id="guid"
        type="text"
        bind:this={guidInput}
        on:input={(isValid = null)}
        bind:value={guidValue}
        size="39"
        use:registerFocus
    />
    <button type="submit">Validate</button>

    <p class="m-5">
        {#if isValid}
            <h3 class="text-success">Valid!</h3>
            <span>
                <span class="font-italic">{guidValue}</span> is a valid
                <a href="/versions/#uuidV{guidVersion}">v{guidVersion} UUID</a
                >.</span
            >
        {/if}

        {#if isValid === false}
            <h3 class="text-danger">Not Valid</h3>
        {/if}
    </p>
</form>

<style>
    #guid {
        font-size: small;
    }

    @media (min-width: 640px) {
        #guid {
            font-size: medium;
        }
    }
</style>
