<script>
  import { onMount, onDestroy } from "svelte";
  import { globalHistory } from "svelte-navigator/src/history";

  let unsub;

  onMount(async () => {
    unsub = globalHistory.listen(() => {
      fetch("/.netlify/functions/log-request").then();
    });
  });

  onDestroy(() => {
    unsub();
  });
</script>
