<script lang="ts">
  import { v4 as uuidv4 } from "uuid";
  import { onMount } from "svelte";
  import CopyToClipboardButton from "../components/copy-to-clipboard-button.svelte";
  import GuidList from "../components/guid-list.svelte";

  let guidCount: number = 1;
  let guidCountInput;
  let guids: string[] = [uuidv4()];
  let copyToClipboardButton;

  function submit() {
    guids = Array.from({ length: guidCount }, () => uuidv4());
  }

  onMount(() => guidCountInput.focus());
</script>

<svelte:head>
  <title>Guidgenerator.io - Generator</title>
</svelte:head>

<form on:submit|preventDefault={submit}>
  <h2 class="m-5">How many GUIDs do you want:</h2>
  <p>
    <input
      id="numberOfGuids"
      type="number"
      bind:this={guidCountInput}
      bind:value={guidCount}
      on:copy={() => copyToClipboardButton.copyGuidsToClipboard()}
      min="1"
      max="999"
    />
    <button type="submit">Generate</button>
    <CopyToClipboardButton bind:this={copyToClipboardButton} {guids} />
  </p>
</form>

<p class="m-5">
  <GuidList {guids} />
</p>

<style>
  header {
    padding-top: 20vh;
  }
</style>
