<svelte:head>
	<title>Guidgenerator.io - Guid Versions Overview</title>
</svelte:head>

<article>
    <h1>Versions of UUIDs</h1>

    UUID (Universally Unique Identifier) is a standardized identifier that is
    used to uniquely identify information in computer systems. UUIDs are
    typically represented as a 128-bit value and are commonly displayed in
    human-readable form as a sequence of 32 hexadecimal digits, usually
    separated by hyphens to improve readability. The format of a UUID allows for
    an extremely low probability of duplication, even when generated on
    different systems at the same time. There are several versions of UUIDs,
    each designed for specific use cases and scenarios. Let's explore the
    different UUID versions:

    <section id="uuidV1">
        <h2 class="mt-5">UUID Version 1 (Time-based UUID):</h2>

        <ul>
            <li>
                This version of UUID is based on the host's MAC address and the
                current timestamp (in nanoseconds) since a specified epoch
                (usually the Unix epoch - January 1, 1970). Additionally, a
                clock sequence is included to handle potential collisions in
                case multiple UUIDs are generated within the same timestamp.
            </li>
            <li>
                The first 4 bits of the UUID are set to "time-based" (version
                1), and the subsequent 60 bits represent the timestamp.
            </li>
            <li>
                Version 1 UUIDs are unique as long as the MAC address is unique,
                but they may expose some privacy concerns since they include the
                MAC address of the generating host.
            </li>
        </ul>
    </section>

    <section id="uuidV2">
        <h2 class="mt-5">UUID Version 2 (DCE Security UUID):</h2>

        <ul>
            <li>
                This version is rarely used and was designed for backward
                compatibility with the Distributed Computing Environment (DCE)
                security standards. It includes a POSIX UID (User Identifier)
                and the current timestamp as its components.
            </li>
            <li>
                Due to its limited use and potential security issues, it's not
                widely adopted in modern systems.
            </li>
        </ul>
    </section>

    <section id="uuidV3">
        <h2 class="mt-5">UUID Version 3 (Name-based UUID using MD5):</h2>

        <ul>
            <li>
                Version 3 UUIDs are generated based on a namespace identifier
                (usually another UUID) and a name. The MD5 hashing algorithm is
                applied to the concatenation of the namespace and name to
                produce a unique UUID.
            </li>
            <li>
                This version is deterministic, meaning that the same namespace
                and name will always generate the same UUID. It is useful when a
                stable, reproducible UUID is needed for a given set of input
                data.
            </li>
        </ul>
    </section>

    <section id="uuidV4">
        <h2 class="mt-5">UUID Version 4 (Random UUID):</h2>

        <ul>
            <li>
                Version 4 UUIDs are generated using random or pseudo-random
                numbers. Theoretically, there is no guarantee of uniqueness, but
                the probability of collisions is extremely low due to the large
                number of possible combinations.
            </li>
            <li>
                These UUIDs are not generated based on any particular system
                information and are suitable for various general-purpose
                scenarios.
            </li>
        </ul>
    </section>

    <section id="uuidV5">
        <h2 class="mt-5">UUID Version 5 (Name-based UUID using SHA-1):</h2>

        <ul>
            <li>
                Similar to Version 3, this version generates UUIDs based on a
                namespace and name, but it uses the more secure SHA-1 hashing
                algorithm instead of MD5.
            </li>
            <li>
                Like Version 3, this version is deterministic and ensures that
                the same namespace and name will always generate the same UUID.
            </li>
        </ul>
    </section>

    <section class="mt-5">
        <p>
            Each UUID version has its unique characteristics and use cases.
            Version 4 (Random UUID) is the most commonly used in practice, as it
            provides a good balance between simplicity and a low probability of
            collisions. However, for specific scenarios where deterministic
            UUIDs are required, Version 3 or Version 5 can be used based on the
            hashing algorithm preference and security considerations.
        </p>
    </section>
</article>

<style>
    article {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        text-align: justify;
    }

    :target {
        -webkit-animation: target-fade 1s;
        -moz-animation: target-fade 1s;
        -o-animation: target-fade 1s;
        animation: target-fade 1s;
    }

    @-webkit-keyframes target-fade {
        from {
            background-color: var(--main-color);
        }
        to {
            background-color: transparent;
        }
    }

    @-moz-keyframes target-fade {
        from {
            background-color: var(--main-color);
        }
        to {
            background-color: transparent;
        }
    }

    @-o-keyframes target-fade {
        from {
            background-color: var(--main-color);
        }
        to {
            background-color: transparent;
        }
    }

    @keyframes target-fade {
        from {
            background-color: var(--main-color);
        }
        to {
            background-color: transparent;
        }
    }
</style>
