<script lang="ts">
  export let guids: string[] = [];
</script>

<div class="guid">
  <p>
    {#each guids as guid}
      {guid}<br />
    {/each}
  </p>
</div>

<style>
  .guid {
    color: var(--main-color);
    font-family: "Courier New", Courier, monospace;
    font-size: 1.2em;
    font-weight: 100;
  }
</style>
