<script lang="ts">
  import { Router, Route, Link } from "svelte-navigator";
  import Generator from "./pages/generator.svelte";
  import Versions from "./pages/versions.svelte";
  import Impressum from "./pages/impressum.svelte";
  import RequestLogger from "./components/request-logger.svelte";
  import Validator from "./pages/validator.svelte";
  import Toasts from "./components/toast/toasts.svelte";
</script>

<RequestLogger />

<main>
  <nav
    class="navbar navbar-expand-lg navbar-dark"
    style="background-color: #990060;"
    aria-label="Ninth navbar example"
  >
    <div class="container-xl">
      <a class="navbar-brand" href="https://guidgenerator.io/"
        >Guid Generator .io</a
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample07XL"
        aria-controls="navbarsExample07XL"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div class="collapse navbar-collapse" id="navbarsExample07XL">
        <Router>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <Link to="/" class="nav-link">Generator</Link>
            </li>
            <li class="nav-item">
              <Link to="/validate" class="nav-link">Validator</Link>
            </li>
            <li class="nav-item">
              <Link to="/versions" class="nav-link">Guid Versions</Link>
            </li>
          </ul>
        </Router>
      </div>
    </div>
  </nav>

  <Toasts />

  <div class="container-xl mb-4 pt-3 text-center">
    <Router>
      <Route path="/" primary={false}>
        <Generator />
      </Route>

      <Route path="/validate">
        <Validator />
      </Route>

      <Route path="/versions">
        <Versions />
      </Route>

      <Route path="impressum">
        <Impressum />
      </Route>
    </Router>
  </div>

  <div class="container">
    <footer class="py-3 my-4">
      <Router>
        <ul
          class="nav justify-content-center border-top pt-3 mb-3"
          style="border-color: #990060 !important;"
        >
          <li class="nav-item">
            <Link to="/" class="nav-link px-4 text-muted">Generator</Link>
          </li>
          <li class="nav-item">
            <Link to="/validate" class="nav-link px-4 text-muted"
              >Validator</Link
            >
          </li>
          <li class="nav-item">
            <Link to="/versions" class="nav-link px-4 text-muted"
              >Guid Versions</Link
            >
          </li>
          <li class="nav-item">
            <Link to="/impressum" class="nav-link px-4 text-muted"
              >Impressum</Link
            >
          </li>
        </ul>
      </Router>
      <p class="text-center text-muted">© Guidgenerator.io</p>
    </footer>
  </div>
</main>
